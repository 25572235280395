.vc-container {
    .yield-day {
        background-color: #facc11;
        border-radius: 100%;
    }
}

.yield-day-box {
    display: inline-block;
    height: 14px;
    width: 14px;
    background-color: #facc11;
    border-radius: 100%;
}

.pac-container {
    z-index: 2500 !important;
}
