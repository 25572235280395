
.account-auth
{
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;

    @media (max-width: 768px) {
        overflow-y: scroll;
        overflow-x: hidden;
    }


    .auth-section {
        background-color: $light-blue;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .auth-brand
    {
        font-weight: 600;
        font-size: 1.8em;
        color: $account_primary;
        position: absolute;
        padding: 80px 10vw;
        text-decoration: none !important;
        img
        {
            height: 30px;
        }
    }

    .auth-branding
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 10vw;
        width: 100%;
        height: 100vh;
        h1
        {
            font-size: 5em;
            color: $primary;
            margin: 0;
            font-family: "Open Sans", sans-serif;
        }
    }
    .auth-form
    {
        width: 100%;
        padding: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        form
        {
            max-width: 330px;
            width: 100%;
        }

        .auth-brand-inline
        {
            max-width: 250px;
            width: 100%;
        }

        .h5
        {
            color: #000;
            padding-bottom: 10px;
            font-family: Montserrat;
        }
        .input-group
        {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
            i
            {
                color: $account_text_muted;
                width: 30px;
                text-align: center;
            }
            input
            {
                border: 0 !important;
                font-size: 1em;
                &::placeholder
                {
                    color: $account_text_muted;
                }
            }
        }

        .remember-group
        {
            label
            {
                display: flex;
                align-items: center;
                margin: 0;
                line-height: 1;
                font-weight: 500;
            }
        }
        .action
        {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .btn-auth
            {
                background-color: #fff;
                color: $primary;
                font-weight: 600;
            }
            .redirect
            {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                a
                {
                    color: black;
                    padding-left: 10px;
                }
            }
        }

        .section-title {
            font-weight: 500;
            line-height: normal;
            color: black;
        }

        .bystored-section {
            display: block;
            max-width: 330px;
            width: 100%;
        }
    }
}
