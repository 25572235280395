$account_background_block: $gray-1;
$account_background_muted: $gray-1;
$account_primary: $primary;
$account_white: #FFFFFF;
$account_text_muted: $gray-3;
$account_text_dark: $dark;
$nav-height: 70px;



.account_v2
{
    .btn
    {
        border-radius: 3px;
        box-shadow: none;
        padding: 5px 15px;
        font-size: 1em;
        font-family: "Montserrat", sans-serif;
    }

    .scroll-x
    {
        overflow-x: auto;
    }

    .alert
    {
        border: 0 !important;
        border-radius: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Montserrat;
        font-size: 1em;

        .fa-times
        {
            color: #1B1B1B;
            cursor: pointer;
        }
        span
        {
            flex: 1;
            padding: 0 10px;
        }
    }

    .alert-danger
    {
        background-color: rgba(#FF564A,0.1);
        color: #FF564A;
    }

    .alert-success
    {
        background-color: rgba(#39C463,0.1);
        color: #39C463;
    }

    .alert-warning
    {
        background-color: rgba(255,243,205,0.8);
        color: #b49024;
        i
        {
            color: #b49024;
        }
    }

    .credit_note_row {
        font-size: .9em;
    }
    .sponsorship-sub-title {
        font-size: 1.3rem;
        line-height: 1.4;
    }
}

.popup-confirm
{
    text-align: center;
    padding: 30px 50px !important;

    .confirm-title
    {
        font-weight: 700;
        font-size: 1.5em;
        text-align: center;
    }
    .confirm-btns
    {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        button
        {
            width: 100%;
            max-width: 200px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}


.account-load
{
    width: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $account_text_muted;
}

.side-phone-menue-container
{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#000,0.1);
    z-index: 1000;

    nav.nav-account-phone-side
    {
        background-color: #fff;
        width: 400px;
        box-shadow: 0 0 20px rgba(#000,0.1);
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .phone-head
        {
            height: $nav-height;
            display: flex;
            align-items: center;
            padding: 0 25px;
            justify-content: flex-start;
            img
            {
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }

        .phone-content
        {
            height: calc(100vh - 60px);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            ul
            {
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                li
                {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    a
                    {
                        display: block;
                        width: 100%;
                        padding: 15px 30px;
                        font-size: 1.3em;
                        font-weight: 600;
                    }
                }
            }


        }
    }
}



nav.nav-account-phone
{
    width: 100%;
    height: $nav-height;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 50;

    button
    {
        height: $nav-height;
        width: $nav-height;
        border: 0 !important;
        background-color: transparent;
        color: #fff;
        font-size: 1.7em;
        outline: none !important;
    }
    .brand
    {
        img
        {
            height: 30px;
        }
    }
}


nav.nav-account-mobile
{
    width: 100%;
    background-color: $primary;
    position: relative;
    z-index: 50;


    .links
    {
        flex: 1;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 15px;
        justify-content: center;
        li
        {
            list-style: none;
            padding: 15px 20px;
            text-align: center;
            a
            {
                color: #fff;
                text-decoration: none !important;
            }
            .active
            {
                font-weight: 600;
            }
        }
    }
}



nav.nav-account
{
    width: 100%;
    height: $nav-height;
    background-color: $primary;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 60;

    .container
    {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .brand
    {
        span
        {
            font-weight: 600;
            font-size: 1.8em;
            color: $account_primary;
        }
        img
        {
            height: 30px;
        }
    }
    .links
    {
        flex: 1;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 30px;
        li
        {
            list-style: none;
            padding: 0 20px;
            text-align: center;
            a
            {
                color: #fff;
                text-decoration: none !important;
            }
            .active
            {
                font-weight: 600;
            }
        }
    }
    .menus
    {
        display: flex;
        i
        {
            color: #fff;
        }
        .btn-menu-icon
        {
            box-shadow: none !important;
            color: #fff;
        }
        .btn
        {
            display: flex;
            align-items: center;
            height: 31px;
            justify-content: center;
        }
        .cart-notif
        {
            height: 12px;
            width: 12px;
            border-radius: 12px;

            background-color: red;
            position: absolute;
            margin-left: 9px;
            margin-top: -7px;
        }
    }
}

.border-bottom-grey {
    border-bottom: 1px solid grey;
}

.font-13px {
    font-size: 13px;
}

.account-inventory-search
{
    display: flex;
    align-items: center;
    background-color: $account_background_muted;
    margin-top: 20px;
    padding: 8px 15px;
    i
    {
        font-size: 1.2em;
        color:$account_text_dark;
    }
    .form-control
    {
        border: 0 !important;
        background-color: transparent;
        color: darken($account_text_muted,10);
        font-family: "Open Sans", sans-serif;
        outline: none !important;
        box-shadow: none !important;
        &::placeholder
        {
            color: $account_text_muted;
        }
    }

}



.inventory-found
{
    font-weight: 400;
    font-size: 1.5em;
    color: $account_text_dark;
    font-family: "Open Sans", sans-serif;
}

.account-filters
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    .filter
    {
        display: flex;
        align-items: center;
        padding-right: 25px;
        label
        {
            margin: 0;
            margin-right: 15px;
            color: $account_primary;
            font-family: "Open Sans", sans-serif;
        }
        select
        {
            border: 1px solid $account_primary;
            background-color: #fff;
            padding: 5px 15px;
            color: $account_text_dark;
            font-family: "Open Sans", sans-serif;
        }
    }
    .filter-unique
    {
        color: $account_text_muted;
        a
        {
            padding: 0 10px;
            color: $account_text_dark;
            text-decoration: none !important;
        }
    }
    .select-all-container
    {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}


.inventory-element-show
{
    margin-bottom: 10px;
    cursor: pointer;


    .ratio-phone
    {
        border-radius: 3px;
        overflow: hidden;
        .cta
        {
            display: flex;
            justify-content: flex-end;
            padding: 5px;
            .btn
            {
                color: $account_primary;
                box-shadow: none !important;
            }
        }
        .content
        {
            background-position: center center;
            -webkit-background-size: cover;
            background-size: cover;
        }
    }

    .infos
    {
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        .title
        {
            font-size: 1.1em;
            font-family: "Montserrat", sans-serif;
            color: $account_primary;
            margin-bottom: 5px;
            cursor: pointer;
            &:hover
            {
                text-decoration: underline;
            }
        }
        small
        {
            color: $account_text_muted;
        }
        .attributes
        {
            margin-bottom: 5px;
            span
            {
                color: $account_text_muted;
            }
        }
    }
}


.account-tags
{
    .tag
    {
        background-color: $account_background_muted;
        color: $account_primary;
        padding: 3px 10px;
        border-radius: 3px;
        margin-right: 5px;
    }
}

.account-address-show
{
    background-color: $account_background_block;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .title
    {
        display: flex;
        align-items: center;
        span
        {
            color: $account_text_dark;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
            font-size: 1.1em;
        }
        button
        {
            margin-left: 5px;
        }
    }
    .address
    {
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        font-size: 1.1em;
        color: $account_text_dark;
    }
    ul
    {
        margin: 0 15px;
        padding: 0;
        li
        {
            color: $account_text_dark;
            padding-bottom: 3px;
        }
    }
}




.account-block
{
    background-color: $account_background_block;
    padding: 20px;
    color: $account_text_dark;

    &.sponsorship {
        background-color: $light-yellow;
    }
}

.account-care-block
{
    .care-row
    {
        padding: 20px 0;
    }
    .care-image
    {
        background-position-x: 40%;
        background-position-y: center;
        background-size: cover;
        max-height: 200px;
    }
    .care-info
    {
        display: flex;
        flex-direction: column;
    }
}

.account-block-stats
{
    display: flex;
    flex-direction: column;
    span
    {
        font-family: "Open Sans";
        font-size: 1em;
    }
    b,strong
    {
        font-size: 3.5em;
        font-family: "Open Sans";
        font-weight: 800;
    }
}

.account-block-sponsor,
.account-block-faq {
    padding: 40px;
    background-color: $light-yellow;
}

.account-block-sponsor {
    height: 100%;
    background-color: $light-yellow;

    &__code {
        span {
            padding: 18px 64px 20px 18px;
            background-color: $account_white;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            font-weight: bold;
        }

        .btn {
            margin-left: -32px;
            padding: 16px;
            border-radius: 32px;
            text-transform: uppercase;

            &-outline-primary {
                background-color: $account_white;
            }
        }
    }

    &__share {
        display: grid;
        grid-template-columns: repeat(4, 42px);
        grid-template-rows: 42px;
        grid-gap: 16px;
        margin: 0;
        padding: 0;
        list-style: none;

        &-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-color: $account_primary;
            color: $account_white;
            font-size: 1.5rem;

            &:hover {
                text-decoration: none;
                color: $account_white;
            }
        }
    }

    &__form {
        .form-control {
            height: 53px;
            border: none;

            &::placeholder {
                font-weight: bold;
            }
        }

        .btn {
            width: 100%;
            padding: 16px;
            border-radius: 32px;
            text-transform: uppercase;
        }
    }

    &__faq {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-bottom: 3rem;

        .fa-question,
        .fa-question-circle {
            font-size: 8rem;
            color: $account_primary;
        }

        &-description {
            .h2 {
                font-size: 1.4rem;
                line-height: 1.4;
            }
        }
    }
}

.account-block-faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fa-question-circle {
        margin-bottom: 20px;
        font-size: 2.5rem;
        color: $account_primary;
    }
}

.account-order-input-group
{
    button
    {
        border-right: 0;
        width: 120px;
        &:last-child
        {
            border: 1px solid $primary;
        }
    }
}

.table-account-striped
{
    tbody tr:nth-of-type(odd) {
        background-color: $account_background_block;
    }
}

.table-account
{
    thead
    {
        tr
        {
            th
            {
                border-bottom: none;
                border-top: none;
                font-weight: 600;
                color: $account_text_dark;
            }
        }
    }
    tr
    {
        td
        {
            border-top: none;
            color: $account_text_dark;
            font-family: "Open Sans", sans-serif;
            padding: 15px 10px;
            a
            {
                color: $account_primary;
            }
            .addresses
            {
                b
                {
                    font-weight: 400;
                    margin-right: 10px;
                }
            }
        }
    }
}


.table-account-list
{
    @extend .table-account;
    border-top: 1px solid $account_background_block;
    thead
    {
        tr th
        {
            &:first-child
            {
                padding-left: 40px;
            }
            &:last-child
            {
                padding-right: 30px;
            }
        }
    }
    tbody
    {
        tr td
        {
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-child
            {
                padding-left: 40px;
            }
            &:last-child
            {
                padding-right: 30px;
            }
        }
    }
}



// KF POPUP
.popup-container
{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000,0.5);
    z-index: 1800;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 0.2s fadeIn;
    backdrop-filter: blur(3px);

    .popup
    {
        width: 100%;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 10px 20px rgba(#000,0.1);
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: 0.3s zoomInProductPopup;
        z-index: 1900;
        color: $account_text_dark;

        .popup-head
        {
            height: 56px;
            border-bottom: 1px solid rgba(#000,0.08);
            display: flex;
            align-items: center;
            justify-content: center;
            span
            {
                font-weight: 500;
                font-size: 0.95em;
            }
        }

        .popup-body
        {
            padding: 32px;
            overflow-y: auto;
            width: 100%;
            max-height: 70vh;
            flex: 1;
            .popup-close-container
            {
                height: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
            }
            .popup-title
            {
                width: 100%;
                text-align: center;
            }
        }
        .popup-footer
        {
            width: 100%;
            height: 60px;
            display: flex;
            padding: 8px;
            justify-content: flex-end;
            .btn
            {
                display: flex;
                align-items: center;
                margin-left: 8px;
            }
        }
    }
}

@-webkit-keyframes zoomInProductPopup {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: 1;
  }
}



.element-page
{
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    padding: 40px 0;
    z-index: 70;
    overflow-y: auto;

    .element-images
    {
        height: calc(100vh - 70px);
        max-width: 80vh;
        overflow-y: auto;
        .image
        {
            .content
            {
                background-color: $account_primary;
                background-position: center center;
                -webkit-background-size: cover;
                background-size: cover;
            }
        }
        .image-counter
        {
            width: 100%;
            text-align: center;
            padding: 5px;
        }
    }
    .element-infos
    {
        padding: 50px 50px;
        color: $account_text_dark;
    }
}


.account-cart-container
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000,0.4);
    z-index: 100;
    animation: 0.4s fadeIn;

    .cart-close
    {
        height: 100vh;
    }
    .cart-modal
    {
        width: 100%;
        background-color: #fff;
        height: 100vh;
        animation: 0.4s slideInRight;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cart-head
        {
            display: flex;
            justify-content: space-between;
            padding: 0 50px;
            padding-bottom: 20px;
            span
            {
                font-weight: 600;
                color: $account_text_dark;
                font-size: 1.1em;
            }
            i
            {
                color: $account_text_dark;
                font-size: 1.4em;
                cursor: pointer;
            }
            img
            {
                width: 25px;
                height: 25px;
            }
        }

        .cart-total-table
        {
            color: $account_primary;
            font-family: "Open Sans", sans-serif;
            padding: 0 50px;
            padding-bottom: 20px;
            .total-row
            {
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;
            }
            .total-section-title
            {
                padding-bottom: 10px;
            }
        }

        .cart-btns
        {
            display: flex;
            flex-direction: column;
            padding: 0 50px;
            padding-bottom: 20px;
            .btn
            {
                width: 100%;
                margin-top: 10px;
                padding: 10px 0;
            }
        }
    }
}

.cart-elements
{
    padding: 15px 0;
    //height: 50vh;
    overflow-y: auto;
    flex: 1;

    .element
    {
        display: flex;
        padding: 0 50px;
        padding-top: 15px;

        .ratio-phone
        {
            width: 70px;
            .content
            {
                background-size: cover;
                background-position: center center;
            }
        }
        .infos
        {
            flex: 1;
            padding-left: 20px;
            padding-top: 5px;

            .info-row
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                .cart_item_non_deliverable {
                    color: red;
                }
                span, small
                {
                    color: $account_text_dark;
                }
                i
                {
                    color: $account_primary;
                    cursor: pointer;
                }
            }
        }
    }
}


.account-sponsor
{
    .sponsor-field
    {
        width: 100%;
        background-color: #fff;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span
        {
            font-weight: 800;
            font-size: 1.8em;
            font-family: "Open Sans";
        }
    }
}



.popup-iban
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text
    {
        width: 100%;
        max-width: 500px;
    }

    .form-group
    {
        width: 100%;
        max-width: 500px;
        margin-bottom: 45px;
    }

    .btns
    {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        margin-bottom: 50px;

        .btn
        {
            margin-bottom: 10px;
            font-weight: 600;
            padding: 10px 20px;
        }
    }

    p
    {
        text-align: justify;
    }
}


.account-cart-confirm-side
{
    width: 100%;
    background-color: $gray-1;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title-content
    {
        text-align: center;
        padding:30px;
    }
    .cart-elements
    {
        flex: 1;
    }
}

.nav-sub-account
{
    width: 100%;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $dark-blue;
    color: #a0ceff;
    .langs
    {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        a
        {
            color: #c8e8ff;
            text-decoration: none;
            margin-right: 10px;
            &:hover
            {
                color: #fff;
            }
        }
    }
    .btn
    {
        margin-left: 5px;
    }
}

.toast-top-center-confirm-cart {
    top: 350px;
    left: 400px;
    width: 100%;
}

.cart_item_non_deliverable_container {
    opacity: 0.5;
}

.element_non_deliverable {
    opacity: 0.5;
}

.alert-danger-non-delivery {
    margin-left: 2%;
    margin-right: 23%;
}

.position-at-client-home {
    font-size: 1em;
}

.min-height-90px {
    min-height: 90px;
}

@media (min-width: 992px) {
    .account_v2 {
        .sponsorship-sub-title {
            font-size: 1.875rem;
            line-height: 1.2;

            span {
                display: block;
            }
        }
    }

    .account-block-sponsor,
    .account-block-faq {
        padding: 40px 80px;
    }

    .account-block-faq {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .fa-question-circle {
            margin-right: 20px;
            margin-bottom: 0;
        }
    }
}
