$primary: #1F62F9;
$secondary: #EB1158;
$light:#F3F9FF;
$dark: #103d6b;
$dark-blue: #010819;
$light-blue: #f5f8ff;
$light-yellow: #fffaf0;

$black: #000;
$gray-4:#495057;
$gray-3:#ACB5BD;
$gray-2:#DDE2E5;
$gray-1:#F8F9FA;

// Fonts + libs
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Open+Sans:400,600,700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.3.1/css/all.css');

@import '~bootstrap/scss/bootstrap';
@import "~animate.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

// Bases
@import "bases/mixins";
//@import "bases/responsive";

// Branding
@import "rebranding/typography";
@import "rebranding/bases";
@import "rebranding/account";
@import "rebranding/auth";
@import "rebranding/responsive";
@import "shared";

@import "bases/payments";

// Vue multiselect
.multiselect {
    min-height: calc(1.5em + 0.75rem + 2px);
    font-size: 1em;

    &__select {
        min-height: calc(1.5em + 0.75rem + 2px);
    }

    &__input {
        font-size: 1em;
    }

    &__tags {
        min-height: calc(1.5em + 0.75rem + 2px);
        padding: 6px 40px 6px 8px;

        .multiselect__placeholder {
            padding: 0;
            margin-bottom: 0;
        }
    }

    &__single {
        font-size: 1em;
        margin-bottom: 0;
    }
}

