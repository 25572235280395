.h1,.h2,.h3,.h4,.h5,.h6
{
    color: $gray-4;
    font-family: Montserrat;
    font-style: normal;
}

.h1
{
    font-weight: bold;
    font-size: 3em;
    line-height: 107px;
}
.h2
{
    font-weight: bold;
    font-size: 2.4em;
    line-height: 80px;
}
.h3
{
    font-weight: bold;
    font-size: 2em;
    line-height: 61px;
}
.h4
{
    font-weight: bold;
    font-size: 1.7em;
    line-height: 46px;
}
.h5
{
    font-weight: bold;
    font-size: 1.5em;
    line-height: 34px;
}
.h6
{
    font-weight: bold;
    font-size: 1.2em;
    line-height: 26px;
}
.h4-delivery
{
    color: $gray-4;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    font-size: 1.55em;
    margin-top: 20px;
}
