









@media (max-width: 1200px) {



    .account-auth
    {
        .auth-branding
        {
            h1
            {
                font-size: 2.5em !important;
                line-height: 1.4em !important;
            }
        }
    }

}



@media (max-width: 992px) {

    .account-cart-confirm-side
    {
        height: auto !important;
        padding-bottom: 50px;
    }

    main.quote
    {
        .quote-header-boxes
        {
            margin-bottom: 15px;
            height: auto !important;
        }

        .quote-header-cta
        {
            .cta-title
            {
                padding-bottom: 20px;
            }
        }
    }

}

@media (max-width: 768px) {

    .account-auth
    {
        .auth-branding
        {
            h1,.h1
            {
                font-size: 2em !important;
                line-height: 1.3 !important;
            }
        }
    }
    .account-block-stats
    {
        text-align: center;
        span
        {
            font-weight: 600;
        }
    }
    .account-order-input-group
    {
        display: flex;
        flex-direction: column;

        button
        {
            border: 1px solid #1F62F9 !important;
            margin-bottom: 3px;
        }
    }
}


@media (max-width: 576px) {

    nav.nav-account-mobile
    {
        .links
        {
            flex-direction: column;
        }
    }

    .account-auth
    {
        .auth-form
        {
            padding: 80px 20px !important;

            .action
            {
                display: flex;
                flex-direction: column;
                .redirect
                {
                    display: flex;
                    flex-direction: column;
                }
                button, a
                {
                    margin-top: 20px;
                }
            }
        }
    }

    main.quote
    {
        .quote-header
        {
             .quote-header-top
             {
                 padding: 30px 20px;
             }
        }


        .estimate-send-section
        {
            .send-info-container
            {
                margin-bottom: 20px;
                padding: 0 15px;
            }
        }
    }

}
