body
{
    overflow-x: hidden !important;
    font-family: Montserrat, sans-serif;
    font-size: .8rem;
}

.line-height-1 {
    line-height: 1;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-70 {
    opacity: 0.7;
}

.ratio-phone
{
  @include ratio(9,16);
  overflow: hidden;
  width: 100%;
  .content
  {
    width: 100%;
  }
  img,figure
  {
    @extend .content;
  }
}

.cursor-pointer {
    cursor: pointer;
}

// Override BS style for toastr (because of @import '~bootstrap/scss/bootstrap'; in sass/account.scss)
#toast-container > .toast-error {
    background-color: #bd362f;
}

#toast-container > .toast-success {
    background-color: #51a351;
}

#toast-container > .toast-warning {
    background-color: #f89406;
}

.loading
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    i
    {
        font-size: 1.5em;
        color: #9b9b9b;
    }
}


.alert
{
    border: 0 !important;
    border-radius: 5px !important;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat;
    font-size: 1em;

    .fa-times
    {
        color: #1B1B1B;
    }
    span
    {
        flex: 1;
        padding: 0 10px;
    }
}

.alert-danger
{
    background-color: lighten(#FF564A,35);
    color: #FF564A;
}

.alert-success
{
    background-color: lighten(#39C463,35);
    color: #39C463;
}

.alert-warning
{
    background-color: lighten(#ACB5BD,35);
    color: #212429;
    i
    {
        color: #FF564A;
    }
}


.form-control
{
    border-radius: 8px;
    border: 1px solid #ACB5BD;
    font-size: 1em;
    color: #000;
    &::placeholder
    {
        color: #98A1A9;
    }
}
.form-control-lg
{

}
.form-control-sm
{

}



.btn
{
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 1em;
    font-weight: 600;
    font-family: Montserrat;
}

.btn-light
{
    color: $primary;
    &:hover
    {
        background-color: lighten($primary,55);
        color: $primary;
    }
}

.input-group
{
    .input-group-append,
    .input-group-prepend
    {
        .input-group-text
        {
            font-size: 0.8rem;
        }
    }
}

.square-ratio
{
  @include ratio(1, 1);
  overflow: hidden;
  width: 100%;
  .content
  {
    width: 100%;
  }
  img,figure
  {
    @extend .content;
  }
}

.bg-light-blue {
    background-color: $light-blue;
}
