$gray-1:#F8F9FA;

.payment-method-input, .payment-method-type-input {
    background: $gray-1;
    border: 1px solid $gray-1;
    transition: all 0.2s ease;
    &-check {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    * {
        cursor: pointer;
    }
    &:hover {
        background: $light;
        border: 1px solid $light;
    }
    .form-check-input {
        width: 0;
        height: 0;
        opacity: 0;
    }
}
.payment-method-add-link {
    a {
        text-decoration: none;
    }
}

.payment-method-image {
    img {
        height: 48px;
    }
}

.payment-method-image-small {
    img {
        height: 20px;
    }
}

.payment-method-type-image {
    img {
        height: 48px;
    }
}
